const pageURL = {
  home: "/",
  login: "/login",
  forgetPassword: "/forget-password",
  generatePassword: "/generate",
  changePasswordForget: "/change-password-forget",
  changePassword: "/change-password",

  sprintManagement: "/sprint-management",
  sprintManagementAction: "/sprint-management/:mode/:id?",

  projectManagement: "/project-management",
  projectManagementAction: "/project-management/:mode/:id?",

  staffManagement: "/staff-management",
  staffManagementAction: "/staff-management/:mode/:id?",

  customerManagement: "/customer-management",
  customerManagementAction: "/customer-management/:mode/:id?",

  teamManagement: "/team-management",
  teamManagementAction: "/team-management/:mode/:id?",

  authorityManagement: "/authority-management",

  roleManagement: "/role-management",
  roleManagementAction: "/role-management/:mode/:id?",

  titleManagement: "/title-management",

  sprintReport: "/sprint-report",
  sprintReportDetail: "/sprint-report/:mode/:id",

  eventManagement: "/event-management",
  eventManagementAction: "/event-management/:mode/:id?",

  retroReport: "/retro-report",
  retroReportDetail: "/retro-report/:mode/:id?",

  companyManagement: "/company-management",
  companyManagementAction: "/company-management/:mode/:id?",

  agreementManagement: "/agreement-management",
  agreementManagementAction: "/agreement-management/:mode/:id?",

  announcement: "/announcements",
  announcementDetail: "/announcements/detail/:id?",

  womensClub: "/womens-club",
  womensClubDetail: "/womens-club/detail/:id?",

  stepCounter: "/step-cunter",
  stepCounterReportManagement: "/stepCounter-management/detail/report/:id?",
  stepCounterUserReportManagement: "/stepCounter-management/detail/report/user/:id?/:userId?",
  stepCounterManagementAction: "/stepCounter-management/:mode/:id?",

  yellowPages: "/yellow-pages",
  yellowPageDetails: "/yellow-pages/detail/:id?",
  yellowPageMyAdverts: "/yellow-pages/my-adverts",
  yellowPageCreate: "/yellow-pages/create",


  contractedCompanies: "/contracted-compainies",
  contractedCompanylist: "/contracted-compainies/list/:id?",
  contractedCompanyDetail: "/contracted-compainies/detail/:id?",

  languageManagement: "/language-management",
  languageManagementAction: "/language-management/:mode/:id?",

  foodManagement: "/food-management",
  foodManagementAction: "/food-management/:mode/:id?",

  menuList: "/menu-list",
  menuManagementAction: "/menu-management/:mode/:id?",

  reservationManagement: "/reservation-management",
  reservationManagementAction: "/reservation-management/:mode/:id?",

  shortcuts: "/shortcuts",
  procedureAndDocuments: "/procedure-and-documents",

  appManagement: "/app-management",
  appManagementAction: "/app-management/:mode/:id?",

  emergencyManagement: "/emergency-management",

  phoneDirectory: "/phone-directory",

  serviceList: "/service-list",

  news: "/news",
  newsDetail: "/news/detail/:id?",

  socialClub: "/social-club",
  socialClubDetail: "/social-club/detail/:id?",

  education: "/educations",
  educationDetail: "/educations/detail/:id?",
  educationQuestions: "/educations/questions/:id?",

  companyCategoryManagement: "/company-category-management",
  companyCategoryManagementAction: "/company-category-management/:mode/:id?",

  notifications: "/notifications",
  notificationManagementAction: "/notification-management/:mode/:id?",

  userManagement: "/user-management",
  userManagementAction: "/user-management/:mode/:id?",

  tenantManagement: "/tenant-management",
  tenantManagementAction: "/tenant-management/:mode/:id?",

  billingManagement: "/billing-management",
  billingManagementAction: "/billing-management/:mode/:id?",

  survays: "/survays",
  survayQuestion: "/survays/questions/:id?",

  feedbackForm: "/feedback-form",
  feedbackManagementReport: "/feedback-management/detail/report/:id?",

  downloadManagement: "/Download",
  downloadManagementIos: "/Download/Ios",

  holidays: "/holidays",

  nearMiss: "/near-miss",
};

export default pageURL;
