import React from "react";
import pageURL from "./utils/pageUrls";

const Home = React.lazy(() => import("./pages/Home"));

// const RoleManagement = React.lazy(() => import("./pages/RoleManagement/index"));
// const RoleManagementAction = React.lazy(() => import("./pages/RoleManagement/Action"));

// const SprintReport = React.lazy(() => import("./pages/SprintReport/index"));
// const SprintReportDetail = React.lazy(() => import("./pages/SprintReport/Action"));

const Announcements = React.lazy(() => import("./pages/announcements/index"));
const AnnouncementDetail = React.lazy(() => import("./pages/announcements/details"));

const WomensClub = React.lazy(() => import("./pages/womanClub/index"));
const WomensClubDetail = React.lazy(() => import("./pages/womanClub/details"));

const StepCounter = React.lazy(() => import("./pages/setepCounter/index"));
// const StepCounterManagementAction = React.lazy(() => import("./pages/StepCounterManagement/Action"));
// const StepCounterReportManagement = React.lazy(() => import("./pages/StepCounterManagement/stepReport"));
// const StepCounterUserReportManagement = React.lazy(() => import("./pages/StepCounterManagement/userStepReport"));

const YellowPages = React.lazy(() => import("./pages/yellowPages/index"));
const YellowPageDetails = React.lazy(() => import("./pages/yellowPages/details"));
const YellowPageMyAdverts = React.lazy(() => import("./pages/yellowPages/myAdverts"));
const YellowPageCreate = React.lazy(() => import("./pages/yellowPages/create"));

const ContractedCompaniyCategories = React.lazy(() => import("./pages/contractedCompanies/index"));
const ContractedCompanyList = React.lazy(() => import("./pages/contractedCompanies/list"));
const ContractedCompanyDetails = React.lazy(() => import("./pages/contractedCompanies/details"));

// const LanguageManagement = React.lazy(() => import("./pages/LanguageManagement/index"));
// const LanguageManagementAction = React.lazy(() => import("./pages/LanguageManagement/Action"));

const MenuList = React.lazy(() => import("./pages/menuList/index"));
// const MenuManagementAction = React.lazy(() => import("./pages/MenuManagement/Action"));

// const ReservationManagement = React.lazy(() => import("./pages/ReservationManagement/index"));
// const ReservationManagementAction = React.lazy(() => import("./pages/ReservationManagement/Action"));

const Shortcusts = React.lazy(() => import("./pages/shortcuts/index"));
const ProcedureAndDocuments = React.lazy(() => import("./pages/ProcedureAndDocuments/index"));

// const AppManagement = React.lazy(() => import("./pages/AppManagement/index"));
// const AppManagementAction = React.lazy(() => import("./pages/AppManagement/Action"));

// const EmergencyManagement = React.lazy(() => import("./pages/EmergencyManagement/index"));

// const EducationCategoryManagement = React.lazy(() => import("./pages/EducationCategoryManagement/index"));
// const EducationCategoryManagementAction = React.lazy(() => import("./pages/EducationCategoryManagement/Action"));

// const CompanyCategoryManagement = React.lazy(() => import("./pages/CompanyCategoryManagement/index"));
// const CompanyCategoryManagementAction = React.lazy(() => import("./pages/CompanyCategoryManagement/Action"));

const PhoneDirectory = React.lazy(() => import("./pages/phoneDirectory"));
// const PhoneManagementAction = React.lazy(() => import("./pages/PhoneManagement/Action"));

const ServiceList = React.lazy(() => import("./pages/serviceList/index"));

// const ServiceManagementAction = React.lazy(() => import("./pages/ServiceManagement/Action"));

// const FoodManagement = React.lazy(() => import("./pages/FoodManagement/index"));
// const FoodManagementAction = React.lazy(() => import("./pages/FoodManagement/Action"));

const News = React.lazy(() => import("./pages/news/index"));
const NewsDetail = React.lazy(() => import("./pages/news/details"));

const SocialClub = React.lazy(() => import("./pages/socialClub/index"));
const SocialClubDetail = React.lazy(() => import("./pages/socialClub/details"));

const Education = React.lazy(() => import("./pages/education/index"));
const EducationDetail = React.lazy(() => import("./pages/education/details"));
const EducationQuestions = React.lazy(() => import("./pages/education/questions"));

// const CompanyManagement = React.lazy(() => import("./pages/CompanyManagement/index"));
// const CompanyManagementAction = React.lazy(() => import("./pages/CompanyManagement/Action"));

const Notification = React.lazy(() => import("./pages/notifications/index"));
// const NotificationManagementAction = React.lazy(() => import("./pages/NotificationManagement/Action"));

const SurvayList = React.lazy(() => import("./pages/surveys/index"));
const SurvayQuestions = React.lazy(() => import("./pages/surveys/questions"));
// const QuestionnaireManagementAction = React.lazy(() => import("./pages/QuestionnaireManagement/Action"));

const Feedbacks = React.lazy(() => import("./pages/feedbacks"));
// const FeedbackManagementReport = React.lazy(() => import("./pages/FeedbackManagement/report"));

// const UserManagement = React.lazy(() => import("./pages/UserManagement/index"));
// const UserManagementAction = React.lazy(() => import("./pages/UserManagement/Action"));

// const TenantManagement = React.lazy(() => import("./pages/TenantManagement/index"));
// const TenantManagementAction = React.lazy(() => import("./pages/TenantManagement/Action"));

const Holidays = React.lazy(() => import("./pages/holidays/index"));
const NearMissList = React.lazy(() => import("./pages/nearMiss/index"));

const routes = [
  { path: pageURL.home, exact: true, name: "general.home", component: Home },

  // { path: pageURL.roleManagement, exact: true, name: "general.roleManagement", component: RoleManagement, code: "RL001" },
  // { path: pageURL.roleManagementAction, exact: true, name: "general.roleManagement", component: RoleManagementAction, code: "RL002" },

  // { path: pageURL.sprintReport, exact: true, name: "general.sprintReports", component: SprintReport },
  // { path: pageURL.sprintReportDetail, exact: true, name: "general.sprintReports", component: SprintReportDetail },

  { path: pageURL.survays, exact: true, name: "general.questionnaireManagement", component: SurvayList, code: "Q001" },
  { path: pageURL.survayQuestion, exact: true, name: "general.questionnaireManagement", component: SurvayQuestions, code: "Q002" },

  { path: pageURL.feedbackForm, exact: true, name: "general.feedbackForms", component: Feedbacks, code: "Q001" },
  // { path: pageURL.feedbackManagementReport, exact: true, name: "general.feedbackManagement", component: FeedbackManagementReport, code: "Q002" },

  // { path: pageURL.userManagement, exact: true, name: "general.userManagement", component: UserManagement, code: "U001" },
  // { path: pageURL.userManagementAction, exact: true, name: "general.userManagement", component: UserManagementAction, code: "U002" },

  // { path: pageURL.tenantManagement, exact: true, name: "general.tenantManagement", component: TenantManagement, code: "T001" },
  // { path: pageURL.tenantManagementAction, exact: true, name: "general.tenantManagement", component: TenantManagementAction, code: "T002" },

  { path: pageURL.announcement, exact: true, name: "general.announcementManagement", component: Announcements, code: "AN001" },
  { path: pageURL.announcementDetail, exact: true, name: "general.announcementManagement", component: AnnouncementDetail, code: "AN002" },

  { path: pageURL.womensClub, exact: true, name: "general.womenManagement", component: WomensClub, code: "AN000" },
  { path: pageURL.womensClubDetail, exact: true, name: "general.womenManagement", component: WomensClubDetail, code: "AN000" },

  { path: pageURL.stepCounter, exact: true, name: "general.stepCounterManagement", component: StepCounter, code: "AN001" },
  // { path: pageURL.stepCounterManagementAction, exact: true, name: "general.stepCounterManagement", component: StepCounterManagementAction, code: "AN002" },
  // { path: pageURL.stepCounterReportManagement, exact: true, name: "general.stepCounterManagement", component: StepCounterReportManagement, code: "AN002" },
  // { path: pageURL.stepCounterUserReportManagement, exact: true, name: "general.stepCounterManagement", component: StepCounterUserReportManagement, code: "AN002" },

  { path: pageURL.yellowPages, exact: true, name: "general.advertisementManagement", component: YellowPages, code: "AD001" },
  { path: pageURL.yellowPageDetails, exact: true, name: "general.advertisementManagement", component: YellowPageDetails, code: "AD002" },
  { path: pageURL.yellowPageMyAdverts, exact: true, name: "general.advertisementManagement", component: YellowPageMyAdverts, code: "AD003" },
  { path: pageURL.yellowPageCreate, exact: true, name: "general.advertisementManagement", component: YellowPageCreate, code: "AD004" },

  { path: pageURL.contractedCompanies, exact: true, name: "general.advertisementManagement", component: ContractedCompaniyCategories, code: "CCCM000" },
  { path: pageURL.contractedCompanylist, exact: true, name: "general.advertisementManagement", component: ContractedCompanyList, code: "CCM001" },
  { path: pageURL.contractedCompanyDetail, exact: true, name: "general.advertisementManagement", component: ContractedCompanyDetails, code: "CCM002" },

  // { path: pageURL.languageManagement, exact: true, name: "general.languageManagement", component: LanguageManagement, code: "asd" },
  // { path: pageURL.languageManagementAction, exact: true, name: "general.languageManagement", component: LanguageManagementAction, code: "asd" },

  { path: pageURL.menuList, exact: true, name: "general.menuManagement", component: MenuList, code: "F001" },
  // { path: pageURL.menuManagementAction, exact: true, name: "general.menuManagement", component: MenuManagementAction, code: "F002" },

  // { path: pageURL.reservationManagement, exact: true, name: "general.reservationManagement", component: ReservationManagement, code: "R001" },
  // { path: pageURL.reservationManagementAction, exact: true, name: "general.reservationManagement", component: ReservationManagementAction, code: "R002" },

  { path: pageURL.shortcuts, exact: true, name: "general.shortcutManagement", component: Shortcusts, code: "SH001" },
  { path: pageURL.procedureAndDocuments, exact: true, name: "general.shortcutManagement", component: ProcedureAndDocuments, code: "SH001" },

  // { path: pageURL.shortcutManagementAction, exact: true, name: "general.shortcutManagement", component: ShortcutManagementAction, code: "SH002" },

  // { path: pageURL.appManagement, exact: true, name: "general.appManagement", component: AppManagement, code: "SH001" },
  // { path: pageURL.appManagementAction, exact: true, name: "general.appManagement", component: AppManagementAction, code: "SH002" },

  // { path: pageURL.emergencyManagement, exact: true, name: "general.emergencyManagement", component: EmergencyManagement, code: "SH001" },

  // { path: pageURL.educationCategoryManagement, exact: true, name: "general.educationCategoryManagement", component: EducationCategoryManagement, code: "SH001" },
  // { path: pageURL.educationCategoryManagementAction, exact: true, name: "general.educationCategoryManagement", component: EducationCategoryManagementAction, code: "SH002" },

  // { path: pageURL.companyCategoryManagement, exact: true, name: "general.companyCategoryManagement", component: CompanyCategoryManagement, code: "SH001" },
  // { path: pageURL.companyCategoryManagementAction, exact: true, name: "general.companyCategoryManagement", component: CompanyCategoryManagementAction, code: "SH002" },

  { path: pageURL.phoneDirectory, exact: true, name: "general.phoneManagement", component: PhoneDirectory, code: "asd" },
  // { path: pageURL.phoneManagementAction, exact: true, name: "general.phoneManagement", component: PhoneManagementAction, code: "asd" },

  { path: pageURL.serviceList, exact: true, name: "general.serviceManagement", component: ServiceList, code: "S001" },
  // { path: pageURL.serviceManagementAction, exact: true, name: "general.serviceManagement", component: ServiceManagementAction, code: "S002" },

  // { path: pageURL.foodManagement, exact: true, name: "general.foodManagement", component: FoodManagement, code: "F001" },
  // { path: pageURL.foodManagementAction, exact: true, name: "general.foodManagement", component: FoodManagementAction, code: "F002" },

  { path: pageURL.news, exact: true, name: "general.newsManagement", component: News, code: "N001" },
  { path: pageURL.newsDetail, exact: true, name: "general.newsManagement", component: NewsDetail, code: "N002" },

  { path: pageURL.socialClub, exact: true, name: "general.socialManagement", component: SocialClub, code: "N001" },
  { path: pageURL.socialClubDetail, exact: true, name: "general.socialManagement", component: SocialClubDetail, code: "N002" },

  { path: pageURL.education, exact: true, name: "general.educationManagement", component: Education, code: "E001" },
  { path: pageURL.educationDetail, exact: true, name: "general.EducationManagement", component: EducationDetail, code: "E002" },
  { path: pageURL.educationQuestions, exact: true, name: "general.EducationManagement", component: EducationQuestions, code: "E002" },

  // { path: pageURL.companyManagement, exact: true, name: "general.companyManagement", component: CompanyManagement, code: "N001" },
  // { path: pageURL.companyManagementAction, exact: true, name: "general.companyManagement", component: CompanyManagementAction, code: "N002" },

  { path: pageURL.notifications, exact: true, name: "general.notificationManagement", component: Notification, code: "N001" },
  // { path: pageURL.notificationManagementAction, exact: true, name: "general.notificationManagement", component: NotificationManagementAction, code: "N001" },
  { path: pageURL.holidays, exact: true, name: "general.holidays", component: Holidays, code: "N001" },
  { path: pageURL.nearMiss, exact: true, name: "general.holidays", component: NearMissList, code: "NM001" },
];

export default routes;
