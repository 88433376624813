import React, { useContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import routes from "../routes";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  const { userStorage } = useContext(UserContext);

  const routeAuth = routes.filter((index) => {
    if (index.path === window.location.pathname) {
      if (userStorage?.permissionCodes.includes(index.code)) {
        return true;
      }
    }
  });

  return <Route {...rest} render={(props) => (token && routeAuth.map((i) => i.exact) ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default PrivateRoute;
